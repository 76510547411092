var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"production_picta_9a4d6e2ae6c1633b3dcbef347e992d221dd1f9de"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { Environments } from './constants';

Sentry.init({
  dsn: 'https://ed090ecb7ee84ea6a49bf2a642273416@o933387.ingest.sentry.io/5882530',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1, // transactions
  sampleRate: 0.5, // Errors

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // disable sentry for non production env
  enabled:
    process.env.NEXT_PUBLIC_GCP_ENV === Environments.PRODUCTION &&
    !process.env.TEST_MODE,

  // CUSTOM
  environment: `${process.env.NEXT_PUBLIC_PARTNER}_${process.env.NEXT_PUBLIC_GCP_ENV}`,

  // variable used to attached sourcemap uploaded to sentry
  release: `${process.env.NEXT_PUBLIC_GCP_ENV}_${process.env.NEXT_PUBLIC_PARTNER}_${process.env.NEXT_PUBLIC_COMMIT_SHA}`,
});
